import { Date } from "core-js"
// import { prototype } from "vue/types/umd"

/**
 * Created by jerry on 2019/6/15.
 */
var SIGN_REGEXP = /([YMDhsm])(\1*)/g
var DEFAULT_PATTERN = 'YYYY-MM-DD'
function padding (s, len) {
    let l = len - (s + '').length
    for (var i = 0; i < l; i++) { s = '0' + s }
    return s
}



export default {
    formatDate: {
        format: function (date, pattern) {
            pattern = pattern || DEFAULT_PATTERN
            return pattern.replace(SIGN_REGEXP, function ($0) {
                switch ($0.charAt(0)) {
                    case 'Y': return padding(date.getFullYear(), $0.length)
                    case 'M': return padding(date.getMonth() + 1, $0.length)
                    case 'D': return padding(date.getDate(), $0.length)
                    case 'w': return date.getDay() + 1
                    case 'h': return padding(date.getHours(), $0.length)
                    case 'm': return padding(date.getMinutes(), $0.length)
                    case 's': return padding(date.getSeconds(), $0.length)
                }
            })
        }
    },

    /**  字符串时间转Date yyyy-MM-dd hh:mm:ss */
    convertDateFromString(dateString) {
      if (dateString) { 
        var date = new Date(Date.parse(dateString.replace(/-/g,   "/"))); 
        return date;
      }
    },
      

    //获取小时
    getHours:function(){
      var hours = [];
      for (let i = 0; i < 24; i++) {
        if (i < 10) {
          i = "0" + i;
        }
        hours.push("" + i + "时");
      }
      return hours;
    },
    /**  获取周几  */
    getWeek:function(date){
      var week = date.getDay();
      var weekName = ""
      switch (week) {
        case 1:
          weekName = "周一";
          break;

        case 2:
          weekName = "周二";
          break;

        case 3:
          weekName = "周三";
          break;

        case 4:
          weekName = "周四";
          break;

        case 5:
          weekName = "周五";
          break;

        case 6:
          weekName = "周六";
          break;

        case 0:
          weekName = "周日";
          break;
        default:
          break;
      }
      
      return weekName;
    },

    /**  计算 时间是否超过24点  */
    getCurrentDateAfterHour(hourNum){
      if(hourNum == 0){
        return new Date();
      }
      var curretnDate = new Date();
      var afterDate = new Date(new Date().getTime() + hourNum * 3600 * 1000)
      if (curretnDate.getDay() == afterDate.getDay()){
        return afterDate;
      }
      else{
        curretnDate.setHours(23) ;
        return curretnDate;
      }
    },
    
    /** 获得本周的开端日期   */
    getWeekStartDate(time){ //
      var nowDayOfWeek = time.getDay(); //今天本周的第几天
      var nowYear = time.getFullYear(); //当前年 
      var nowMonth = time.getMonth(); //月 
      var nowDay = time.getDate(); //日 
      var weekStartDate = new Date(nowYear, nowMonth, nowDay - nowDayOfWeek+1);
      return weekStartDate; 
    },

    /** 获得本周的停止日期   */
    getWeekEndDate(time){
      var nowDayOfWeek = time.getDay(); //今天本周的第几天
      var nowYear = time.getFullYear(); //当前年 
      var nowMonth = time.getMonth(); //月 
      var nowDay = time.getDate(); //日 
      var weekEndDate = new Date(nowYear, nowMonth, nowDay + (6 - nowDayOfWeek+1));
      return weekEndDate; 
    },
    /**  获得本周周一~周日的年月日  日期  */
    getAWeedkYMD(time){
      var nowDayOfWeek = time.getDay(); //今天本周的第几天
      if(nowDayOfWeek == 0){
        nowDayOfWeek = 7
      }
      
      var nowYear = time.getFullYear(); //当前年 
      var nowMonth = time.getMonth(); //月 
      var nowDay = time.getDate(); //日 
      var ymdArr=[];
      for (var i = 0; i < 7; i++) {
        // ymdArr[i]=[];
        //ymdArr[i][0]=this.formatDate(new Date(this.nowYear, this.nowMonth, this.nowDay - this.nowDayOfWeek+i+1));
        // ymdArr[i][0]=this.formatDate.format(new Date(nowYear, nowMonth, nowDay - nowDayOfWeek+i+1),'YYY-MM-DD');
        // ymdArr[i][1]=this.formatDate.format(new Date(nowYear, nowMonth, nowDay - nowDayOfWeek+i+1), 'MM月DD日');
        ymdArr[i] = this.formatDate.format(new Date(nowYear, nowMonth, nowDay - nowDayOfWeek+i+1), 'DD')
        
      }
      return ymdArr;
    }

}



